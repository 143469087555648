
import { defineComponent } from "vue";
import SettlementEntityIndexList from "@/components/page/kyc/settlement-entity/IndexList.vue";

export default defineComponent({
  name: "settlement-entity-legal-entity",
  components: {
    SettlementEntityIndexList,
  },
  setup() {
    return {};
  },
});
